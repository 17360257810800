import React, { useState } from "react";
import {
  Box,
  Text,
  Image,
  Button,
  Anchor,
  Heading,
  Input,
  Label,
  Icon,
  Form
} from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/register.json";
import {} from "formik";

import { useFormik } from "formik";
import * as Yup from "yup";
import { registerService } from "../../services/auth.service";
import { isEmpty } from "lodash";
import { STORAGE } from "../../config/storage";
import useSession from "../../hooks/useSession";
import { useNavigate } from "react-router-dom";
import { useShowToast } from "../../hooks/useShowToast";
import { WithoutAuth } from "../../hoc/WithoutAuth";

function getParameterByName(name, url = window.location.href) {
  try {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  } catch (error) {}

  return "";
}

const SignupSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  name: Yup.string()
    .min(3, "Too Short!")
    .max(50, "Too Long!")
    .required("Name is required"),
  password: Yup.string().min(6, "Too Short!").required("Required"),
  cpassword: Yup.string().min(6, "Too Short!").required("Required"),
  country: Yup.string().required("Required"),
  phone: Yup.string().nullable(),
  address: Yup.string().nullable()
});

export default function Register() {
  window.location.href = "/login";

  const [user, isLoading, setUser] = useSession();
  const navigate = useNavigate();
  const showToast = useShowToast();

  const formik = useFormik({
    initialValues: {
      email: "",
      name: ""
    },
    validationSchema: SignupSchema,
    onSubmit: (values, actions) => {
      handleRegister(values, actions);
    }
  });

  const handleRegister = async (values, actions) => {
    actions.setSubmitting(true);

    // password cpassword
    if (values?.password !== values?.cpassword) {
      actions.setSubmitting(false);
      actions.setFieldError(
        "cpassword",
        "Password and confirm password should be same"
      );
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);

    let ibCode = urlParams?.get("ib_code") ?? "";

    if (ibCode) {
      try {
        ibCode = parseInt(ibCode?.toLowerCase()?.split("ib")[1]);
      } catch (error) {}
    }

    try {
      const result = await registerService(
        values?.name,
        values?.email,
        values?.password,
        values?.country,
        ibCode,
        values?.phone,
        values?.address,
        getParameterByName("utm_source") ?? "",
        getParameterByName("utm_campaign") ?? ""
      );

      console.log(result);

      if (result?.data?.success) {
        const user = {
          ...result?.data?.data?.user,
          token: result?.data?.data?.token ?? null
        };

        if (!isEmpty(user) || !isEmpty(user?.token)) {
          showToast("Successfull", "Account registered successfully");

          navigate("/login");
          actions.setSubmitting(false);
          return;
        }
      } else {
        console.log();
        showToast(
          "Error",
          result?.data?.data?.errors?.length
            ? result?.data?.data?.errors[0]
            : "Bad input",
          "error"
        );
        actions.setSubmitting(false);
        return;
      }
    } catch (error) {}

    showToast("Error", "Something went wrong", "error");
    actions.setSubmitting(false);
  };

  return (
    <WithoutAuth>
      <Box className="mc-register">
        <Box className="mc-register-banner">
          <Image
            className="mc-register-banner-pattern"
            src={data?.pattern.src}
            alt={data?.pattern.alt}
          />
          <Box className="mc-register-banner-content">
            <Heading as="h2" className="mc-register-banner-title">
              The PERFECT forex business platform <br />
            </Heading>
            <Text as="p" className="mc-register-banner-descrip">
              Accounts We Offer
              <br />
              <p>
                <b> STANDARD ACCOUNT</b> <br />
                Our standard account will provide you with a complete experience
                and in-depth market knowledge
              </p>
              <p>
                <b> PRO Account</b> <br />
                The PRO account is the most preferable account for the trader
                which delivers the best spreads.
              </p>
              <p>
                <b> ECN Account</b> <br />
                An ECN account is the most popular among traders nowadays as it
                facilitates tight trade spread.
              </p>
              <p>
                <b>VIP Account</b> <br />A VIP account provides a super tight
                spread and the lowest commission rates to our VIP clients. NOTE
                Charges for both depositing and withdrawing funds are currently
                0.75% per transaction, which may vary.
              </p>
            </Text>
          </Box>
        </Box>
        <Form className="mc-register-form">
          <div className="mc-register-form-wrapper">
            <img
              className="mx-auto mb-4"
              src="/images/qorva-logo.png"
              style={{
                height: "100px",
                width: "200px"
              }}
            />
            <Heading as="h4" className="mc-auth-title">
              {data?.title.from}
            </Heading>

            <form onSubmit={formik.handleSubmit}>
              {data?.input.map((item, index) => (
                <IconField
                  key={index}
                  icon={item.icon}
                  type={item.type}
                  classes={item.fieldSize}
                  placeholder={item.placeholder}
                  passwordVisible={item.passwordVisible}
                  name={item?.name}
                  value={formik?.values[item?.name]}
                  onBlur={formik?.handleBlur}
                  onChange={formik?.handleChange}
                  errorMessage={
                    formik?.errors[item?.name] && formik?.touched[item?.name]
                      ? formik?.errors[item?.name]
                      : false
                  }
                />
              ))}

              <button
                type="button"
                className={`mc-auth-btn ${data?.button.fieldSize}`}
                disabled={formik?.isSubmitting}
                onClick={formik.submitForm}
              >
                <div>
                  {formik?.isSubmitting ? "Submitting" : data?.button.text}
                </div>
              </button>
            </form>
            <Box className="mc-register-navigate">
              <Text as="span">{data?.navigate.title}</Text>
              <Anchor href={data?.navigate.path}>{data?.navigate.text}</Anchor>
            </Box>
          </div>
        </Form>
      </Box>
    </WithoutAuth>
  );
}
